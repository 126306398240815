@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.footer {
    padding-top: 12px;
    min-height: 60px;
    z-index: 100;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    & > a {
        text-decoration: none;
    }

    &__shadow {
        border-radius: $mobile-border-radius $mobile-border-radius 0 0;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    }
}

@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.button {
    width: 30px;
    height: 30px;
    z-index: 5100;
    background-color: #fbfafa;
    border-radius: 10px;
}

.pulse {
   animation: pulse-43bdfc71 2s infinite; 
}

.footer {
    width: 16.6667%;
    height: 100%;
    background-color: $secondary;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
  }
  70% {
      -webkit-box-shadow: 0 0 0 50px rgba(234, 189, 119, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
    box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
  }
  70% {
      -moz-box-shadow: 0 0 0 50px rgba(34, 189, 119, 0);
      box-shadow: 0 0 0 10px rgba(34, 189, 119, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
      box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
  }
}

.help-mobile {
    border-radius: 16px 16px 0px 0px;

    &__header {
        height: 50px;
        width: 100%;
        background: #D6F4E4;
    }

    &__links {
        height: 111px;
    }
}

.q-badge {
    width: 14px;
    height: 14px;
    position: absolute;
    padding-top: 4px;
}
